
form { margin:1em 0;}
label { font-size: .86em; color:#666666; display: inline-block; padding:.5em 0 .25em 0;}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
input[type="password"],
textarea { font-weight: 700;;margin-left:30px;width:100%; padding:10px; background-color:#d6dffd; border:solid 1px #fff; display: inline-block; font-size: 16px;border-radius: 5px;margin-top:10px;color:#222;}
input[type="submit"] { background-color:#999999; color:#ffffff; display: inline-block; border:solid 1px #cccccc; padding:.5em 1em; margin-top:.86em; font-weight: 700; -webkit-transition:.5s all; -moz-transition:.5s all; transition:.5s all; }
input[type="submit"]:hover { text-decoration: none; background-color:#4a717a; }
textarea { height:120px; }
select {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #222;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 10px 0 0 30px;
	border: 1px solid #fff;
	border-radius: 5px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #d6dffd;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  #fff;
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
select::-ms-expand {
	display: none;
}
select:hover {
	border-color: #fff;

}
select:focus {
	border-color: #fff;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
select option {
	font-weight:normal;
	color: #222
}



/* ---------------------------------------------------------------------------------------------------------- 
QUOTER --------------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.question.active { display: block;}
.question.hidden { display: none}
.wrapper{}
.quoteWrap { padding-bottom: 35vh; margin:auto;max-width: 400px; }
.nextButton:disabled {opacity:0;}
.nextButton { z-index:100;border-radius:30px; display:block;transition:.5s all; margin-left:auto; margin-right:auto;width:160px; color:#fff; border:0;font-size: 18px;padding:10px; text-align: center;background: rgb(255,0,255);background: linear-gradient(298deg, rgba(255,0,255,1) 0%, rgba(107,44,148,1) 87%);}
.nextButton:hover { width:190px;}
.question {padding:10px 0 15px 80px;background:url(../images/skull-crossbones-white.svg) no-repeat top left; background-size: 60px;margin-bottom: 20px}
.question-q { background: #fff; color: #222; padding:15px 20px;border-radius: 6px}
.question strong { text-transform: capitalize;}
.question-q:before {content: '\25C2';position: absolute;left: -20px;z-index: 20;color: #fff; font-size: 60px;top: 5px}
.question h2 { font-size: 18px; letter-spacing: 0; font-weight: 400}


@media screen and (max-width :650px) {

	input[type="text"],
	input[type="email"],
	select,
	textarea { margin-left: 0 }

}
@media screen and (max-width :420px) {
	.quoteWrap { max-width: 90% }

}


